#searchBar {
    padding-left: 10px;
    
    display: flex;
    flex-direction: row;
    align-items: center;

}

#webView #searchBar {
    grid-column: 1 / span 2;
}

#searchBar > input {
    border: none;
    flex: 1;
    background-color: transparent;
}