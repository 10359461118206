* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.radiusMax {
  border-radius: 14px;
}

.radiusMin {
  border-radius: 7px;
}

.paddingMax {
  padding: 14px;
}

.paddingMin {
  padding: 7px;
}

img {
  object-fit: cover;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

*::-webkit-scrollbar {
  display: none;
}

small {
  color: grey;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: black;
}

button {
  all: unset;
  width: 100%;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.canvas {
  background: radial-gradient(
    /* farthest-side at 60% 55%, */
    #f1e9f5,
    #eaf2fa,
    #f7e7e7,
    #f8efe8,
    #fff0f1,
    #f8eaed
  );
	background-size: 300% 300%;
	animation: gradient 14s ease infinite;

  border-radius: 14px;
}

.canvasDark {
  background: radial-gradient(
    /* to right, */
    #fe7a7a,
    #d489fc,
    #87c3fb,
    /* #f679b8, */
    #f37783,
    #f9849f
  );
	background-size: 700% 700%;
	animation: gradient 15s ease infinite;

  border-radius: 14px;
}


@keyframes gradient {
	0% {
		background-position: 0% 24%;
	}
  27% {
		background-position: 25% 49%;
	}
	50% {
		background-position: 50% 74%;
	}
  74% {
		background-position: 74% 100%;
	}
	100% {
		background-position: 0% 50%;
	}
}


