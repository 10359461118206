#TermsAndConditionsDialog {
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    /* min-width: 400px; */
    width: 90%;
    max-height: 87%;
    z-index: 742;
    box-shadow: 1px 0px 7px #00000080;

    display: flex;
    flex-direction: column;


    padding: 14px;
    margin: auto;
    transform: translate(-50%, -50%);
}
