#webView #Booking {
  grid-column: 1 / span 2;
  grid-row: 2 / span 2;
  
  display: grid;
  gap: 14px;
}
#Booking {
  display: grid;
  gap: 14px;
}
.cardLabel {
  padding: 7px;
  width: 172px;

  font-weight: 600;

  border-radius: 14px 14px 0 0;
}

#EmployeeSelector {
  padding: 7px;
  border-top-left-radius: 0;

  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(142px, 1fr));
  gap: 14px;
  align-items: center;
}

.StaffSelectorItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.StaffSelectorCheckBox {
  width: 17px;
  height: 17px;
  border-radius: 100%;
  border: 2px solid skyblue;
  margin: 0px 14px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DateSelector {
  border-top-left-radius: 0;

  padding: 7px;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(72px, 1fr));
  gap: 12px;
}

.SelectorItem {
  height: 57px;
  border-radius: 14px;
  border: 0.2px solid grey;
  padding: 7px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;
}

.unavailableDate {
  background-color: lightgrey;
  color: grey;
  cursor: not-allowed;
}

.SelectorItemCheck {
  border: 0px solid transparent;
  
  color: white;
  font-weight: 600;
}

.TimeSelector {
  border-top-left-radius: 0;

  padding: 7px;
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: repeat(auto-fit, minmax(72px, 1fr));
  gap: 7px;
}

.TimeSelector-container {
  display: grid;
  column-gap: 14px;
  grid-template-columns: repeat(auto-fit, minmax(242px, 1fr));;

}
