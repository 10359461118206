#RemoveItemConform {
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    width: 300px;
    z-index: 242;
    box-shadow: 1px 0px 7px #00000080;

    padding: 14px;
    margin: auto;
    transform: translate(-50%, -50%);
}

#RemoveItemConform > div {
    display: flex;
    flex-direction: row;
    padding: 7px 0;
}