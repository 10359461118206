#webView {
    padding: 14px;
    margin: auto;
    
    max-width: 1242px;

    display: grid;
    grid-template-columns: 50% 20% 30% ;
    grid-template-rows:
        242px
        42px
        auto
        auto;

    gap: 14px;

    justify-content: center;
    align-content: center;
}

#mobileView {
    width: 100%;
    margin: 0;
    padding: 7px;
    
    display: grid;
    grid-template-columns: auto;
    grid-template-rows:
        auto /* 242px */
        auto
        auto
        auto
        auto
        auto;

    gap: 7px;

    justify-content: stretch;
    align-content: center;
}

#invalidStoreMessage {
    align-items: center;
    align-content: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    text-align: center;

    
}

#invalidStoreMessage > image {
    width: 100vw;
}

.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 200;
    top: 0;
    right: 0;
}

.canvasButton {
    width: 100%;
    height: 42px;
    border-radius: 14px;
    
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.canvasButton > h4 {
    color: white;
}

.loader {
    margin: 300px auto;
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid skyblue; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

.loaderSmall {
    margin: 30px auto;
    border: 7px solid lightgrey; /* Light grey */
    border-top: 7px solid skyblue; /* Blue */
    border-radius: 50%;
    width: 42px;
    height: 42px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}