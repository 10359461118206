#ConfirmBookingPage {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100vh;

    color: white;
}
