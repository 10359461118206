.ServiceTile {
    display: grid;
    grid-template-columns: 42px auto 82px;
    align-items: center;
    padding: 14px 0;
    border-top: 0.2px solid lightgray;
    margin: 0 14px;
}


.ServiceTile > div >  h3,
.ServiceTile > h3 {
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
}
.ServiceTile > h3 {
    text-align: end;
}


.checkBox {
    width: 27px;
    height: 27px;
    border-radius: 100%;
    border: 1px solid grey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkBox_check {
    display: flex;
    justify-content: center;
    
    align-items: center;

    border: none;
}
