
#slider-container {
    display: flex;
    position: relative;
}

#webView #slider-container {
    grid-column: 2 / span 2;
    grid-row: 1 / span 1;
}

#mobileView #slider-container {
    grid-column: 1 / span 1;
    grid-row: 1 / span 1;
    height: 272px;
}

#slider-container > img {
    border-radius: 14px;
    width: 100%;
    max-height: 442px;
}

#sliderButton {
    position: absolute;
    top: 40%;
    width: 100%;
    padding: 9px;
    color: white;
    
    display: flex;
    justify-content: space-between;
    z-index: 90;
}

#sliderButton > a {
    color: white;
    font-size: 22px;
    cursor: pointer;
    user-select: none;
}
